export default {
  GET(state, data) {
    state.catalog = { ...data }
  },
  LIST(state, data) {
    state.catalogs = data
  },
  SET_CATALOG_FORM(state, data) {
    state.catalogForm = { ...data }
  },
  // eslint-disable-next-line no-unused-vars
  RESET_CATALOG(state, _) {
    state.catalog.categories = []
    state.catalog.tags = []
  },
  ADD_BRAND(state, data) {
    state.brandForm = { ...data }
  },
  SET_CATALOG_PRODUCT_BARCODE(state, data) {
    state.catalogProductBarcode = data
  },
  SET_EXPORT(state, data) {
    state.export = { ...data }
  },
  SET_IMPORT(state, data) {
    state.import = { ...data }
  },
}

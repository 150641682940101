export default {
  getTotalDataOfForms: state => ({
    ...state.orderInformationForm,
    ...state.customerInformationForm,
    ...state.insuranceInformationForm,
    ...state.dispatchAndReturnForm,
    ...state.eventInformationForm,
    ...{
      products: [...state.orderItemsRentalTable, ...state.orderItemsSalesTable],
    },
    ...{
      other_charges: state.orderItemsOtherChargesTable,
    },
    ...state.totalsForm,
    ...state.requiresPurchaseOrder,
    payer_account: state.payerAccount.value,
  }),
}

import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import store from '@/store'
import { customActionGet } from '@core/utils/utils'

const endpoint = 'attachments'
const currentCustomerId = () => store.state.customers?.customer?.id
const Actions = crudActions(endpoint)

export default {
  ...Actions,
  'move-to-trash': (ctx, data) => axios.post(`/${endpoint}/${data.id}/move-to-trash`, data),
  'recover-from-trash': (ctx, data) => axios.post(`/${endpoint}/${data.id}/remove-from-trash`, data),
  getActiveAttachment: ({ commit }, queryParams) => customActionGet(`customers/${currentCustomerId()}/attachments`, queryParams, commit, 'LIST'),
  getTrashedAttachment: ({ commit }, queryParams) => customActionGet(`customers/${currentCustomerId()}/attachments/trash`, queryParams, commit, 'LIST'),
}

export default {
  GET(state, data) {
    state.attachment = { ...data }
  },
  LIST(state, data) {
    state.attachments = data
  },
  SET_CUSTOMER_FORM(state, data) {
    state.attachmentForm = { ...data }
  },
}

<template>
  <div
    class="d-flex"
    style="gap: 12px"
  >
    <div
      v-for="(status, idx) in rentalAndSaleStatusFilterTab"
      :key="idx"
    >
      <feather-icon
        v-if="item.state === idx"
        :icon="status.icon"
        size="18"
        :class="`${orderStatesAndStatuses.find(({ status }) => status === item.status).style.styleName}`"
      />
      <feather-icon
        v-else
        :icon="status.icon"
        size="18"
        :class="idx < item.state ? 'ORDERS_STATUS_READY' : 'ORDERS_STATUS_NO_ACTION'"
      />
    </div>
  </div>
</template>

<script>
// Components

// Configs
import config from '@/views/main/orders/config'
import mainConfig from '@/views/main/orders/config'

export default {
  name: 'StatusFilterTable',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { rentalAndSaleStatusFilterTab } = config()
    const { orderStatesAndStatuses } = mainConfig()

    return {
      rentalAndSaleStatusFilterTab,
      orderStatesAndStatuses,
    }
  },
}
</script>
<style lang="scss" scoped>
.btn-custom {
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #D9D9D9 !important;
  margin: 0;
  transition: all 0.2s ease;

  &--active {
    background-color: #646464 !important;
    transition: all 0.2s ease;

    & svg {
      fill: #fff !important;
    }
  }

  &--LPickIcon, &--LBoxIcon {
    & svg {
      position: relative;
      top: 3px;
    }
  }

  &--clear {
    background: transparent !important;

    & svg {
      fill: #D9D9D9;
    }

    &:hover {
      border-color: rgba(100, 100, 100, 0.8);

      & svg {
        fill: rgba(100, 100, 100, 0.8);
      }
    }
  }
}
</style>
<style lang="scss">
.paddingCustom {
  border: 1px dashed #dde3e7;
  border-radius: 0.35rem;
  height: 36px;
  min-width: 115px;

  & .btn {
    padding: 7px 15.5px !important;
  }

  .dropdown-item {
    padding: 0.2rem 1.28rem;
  }
}

.dropNewClass {
  outline: none;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  color: #49454f;
  gap: 9px;
}
</style>

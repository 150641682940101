import mainStore from '@/store'
import rentalSalesModule from '@/store/main/orders/rental-sales'
import attachmentModule from '@/store/main/customer/attachment'
import catalogModul from '@/store/main/catalog'
import { ref } from '@vue/composition-api'
import { differenceInDays } from 'date-fns'

export default function config() {
  // Buttons
  const FORM_BUTTON_TITLE_PRINT = 'Print'
  const FORM_BUTTON_TITLE_SAVE_AS_DRAFT = 'Save as Draft'
  const FORM_BUTTON_TITLE_HOLD_ASSETS = 'Hold Assets'
  const FORM_BUTTON_TITLE_SEND_BY_EMAIL = 'Send by Email'
  const FORM_BUTTON_TITLE_EMAIL_AGAIN = 'Email Again'
  const FORM_BUTTON_TITLE_PLACE_ORDER = 'Place Order'
  const FORM_BUTTON_TITLE_CHANGE_ORDER = 'Change Order'
  const FORM_BUTTON_TITLE_CHARGE = 'Charge'
  const FORM_BUTTON_TITLE_APPROVE = 'Approve'
  const FORM_BUTTON_TITLE_RELEASE_ASSETS = 'Release Assets'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT = 'draft'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER = 'place_order'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL = 'send_email'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_AGAIN = 'email_again'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS = 'hold'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID = 'void'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS = 'release_stocks'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE = 'change_quote'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE = 'approve'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT = 'print'

  // Constants
  const MODULE_NAME = 'rental-sales'
  const MODULE_NAME_ATTACHMENT = 'attachment'
  const MODULE_NAME_CLONE = 'cloneData'
  const MODULE_NAME_CATALOG = 'catalog'

  const store = mainStore
  const catalog = catalogModul
  const model = rentalSalesModule
  const attachmentModel = attachmentModule
  const CREATE_PAGE_TITLE = 'New Sales/Rental Quote'

  const rightSideHoldInProgressButtons = [
    {
      title: FORM_BUTTON_TITLE_PRINT,
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
      classes: 'font-medium-1 font-weight-bold px-3',
      icon: {
        size: '16',
        path: 'LPrintIcon',
      },
    },
    {
      title: FORM_BUTTON_TITLE_RELEASE_ASSETS,
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS,
      classes: 'font-medium-1 font-weight-bold px-3 bgRed',
      variant: 'danger',
      icon: null,
    },
    {
      title: FORM_BUTTON_TITLE_SEND_BY_EMAIL,
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      classes: 'font-medium-1 font-weight-bold px-3',
      icon: null,
    },
    {
      title: FORM_BUTTON_TITLE_APPROVE,
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE,
      classes: 'font-medium-1 font-weight-bold px-3',
      variant: 'success',
      icon: {
        size: '16',
        path: 'LSignatureIconFill',
      },
    },
  ]
  const rightSideHoldReadyButtons = [
    {
      title: FORM_BUTTON_TITLE_PRINT,
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
      classes: 'font-medium-1 font-weight-bold px-3',
      icon: {
        size: '16',
        path: 'LPrintIcon',
      },
    },
    {
      title: FORM_BUTTON_TITLE_RELEASE_ASSETS,
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS,
      classes: 'font-medium-1 font-weight-bold px-3 bgRed',
      variant: 'danger',
      icon: null,
    },
    {
      title: FORM_BUTTON_TITLE_EMAIL_AGAIN,
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_AGAIN,
      classes: 'font-medium-1 font-weight-bold px-3',
      icon: null,
    },
    {
      title: FORM_BUTTON_TITLE_APPROVE,
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE,
      classes: 'font-medium-1 font-weight-bold px-3',
      variant: 'success',
      icon: {
        size: '16',
        path: 'LSignatureIconFill',
      },
    },
  ]

  const leftSideButtons = [
    {
      title: 'Change Quote',
      classes: 'font-medium-1 font-weight-bold px-3',
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
      icon: {
        size: '16',
        path: 'LLoadIcon',
      },
    },
    {
      title: 'Void',
      actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID,
      classes: 'font-medium-1 font-weight-bold px-3 bgRed',
      variant: 'danger',
      icon: {
        size: '16',
        path: 'LNoIcon',
      },
    },
  ]

  const customerSearchQuery = ref('')

  const draftBtn = {
    title: FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
    action: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
    variant: 'secondary',
    icon: '',
  }

  const holdBtn = {
    title: FORM_BUTTON_TITLE_HOLD_ASSETS,
    action: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
    variant: 'danger',
    icon: '',

  }

  const sendByEmail = {
    title: FORM_BUTTON_TITLE_SEND_BY_EMAIL,
    action: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
    variant: 'secondary',
    icon: '',

  }

  const emailAgain = {
    title: FORM_BUTTON_TITLE_EMAIL_AGAIN,
    action: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_AGAIN,
    variant: 'secondary',
    icon: '',

  }

  const approveBtn = {
    title: FORM_BUTTON_TITLE_PLACE_ORDER,
    action: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
    variant: 'success',
    icon: '',
  }

  const changeBtn = {
    title: FORM_BUTTON_TITLE_CHANGE_ORDER,
    action: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
    variant: 'secondary',
    icon: 'LCancelIcon',
  }
  const chargeBtn = {
    title: FORM_BUTTON_TITLE_CHARGE,
    action: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
    variant: 'success',
    icon: 'LCreditCardIconFill',
  }

  const commonFormButtons = [draftBtn, holdBtn, sendByEmail, approveBtn]
  const approveStatusButtons = [changeBtn, sendByEmail, chargeBtn]

  const tableColumns = [
    {
      key: 'order_no',
      label: 'Order No.',
      thStyle: {
        width: '8%',
      },
      isSortable: true,
    },
    {
      key: 'state',
      label: 'State',
      // thStyle: {
      //   width: '13%',
      // },
      isSortable: true,
    },
    {
      key: 'reference',
      label: 'Reference',
      // thStyle: {
      //   width: '12%',
      // },
      isSortable: true,
    },
    {
      key: 'order_date',
      label: 'Order Date',
      thStyle: {
        width: '10%',
      },
      isSortable: true,
    },
    {
      key: 'to',
      label: 'To',
      isSortable: true,
      thStyle: {
        width: '20%',
      },
    },
    {
      key: 'ship_by',
      label: 'Ship By',
      isSortable: true,
      thStyle: {
        width: '12%',
      },
    },
    {
      key: 'return_by',
      label: 'Return by',
      isSortable: true,
      thStyle: {
        width: '12%',
      },
    },
  ]

  const quoteStatusBar = [
    {
      icon: 'LQuoteIconFill',
      title: 'Quote',
      iconSize: '26',
    },
    {
      icon: 'LHoldIcon',
      title: 'Hold',
      iconSize: '26',
    },
    {
      icon: 'LSignatureIconFill',
      title: 'Approve',
      iconSize: '26',
    },
    {
      icon: 'LCreditCardIconFill',
      title: 'Charge',
      iconSize: '26',
    },
  ]

  const draftStatus = {
    icon: 'LQuoteIconFill',
    title: 'Quote',
    iconSize: '26',
  }

  const holdStatus = {
    icon: 'LHoldIcon',
    title: 'Hold',
    iconSize: '26',
  }

  const uploadAttachmentTableCol = [
    {
      label: 'File Name',
      thStyle: {
        width: '20%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Type',
      thStyle: {
        width: '15%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Category',
      thStyle: {
        width: '20%',
        fontWeight: 'bold',
      },
    },
    {
      label: 'Action',
      thStyle: {
        width: '25%',
        fontWeight: 'bold',
      },
    },
    {
      label: '',
      thStyle: {
        width: '30%',
        fontWeight: 'bold',
      },
    },
  ]

  // Store Mutations

  const storeMutationsList = [
    {
      mutation: 'GET',
      payload: {},
    },
    {
      mutation: 'SET_ORDERS_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_CUSTOMER_INFORMATION_FORM',
      payload: {
        attachments: [],
      },
    },
    {
      mutation: 'SET_CUSTOMER_INFORMATION_PURCHASE_ORDER',
      payload: {},
    },
    {
      mutation: 'SET_INSURANCE_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_DISPATCH_AND_RETURN_FORM',
      payload: {},
    },
    {
      mutation: 'SET_EVENT_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ORDER_ITEMS_RENTAL_TABLE',
      payload: [],
    },
    {
      mutation: 'SET_ORDER_ITEMS_SALES_TABLE',
      payload: [],
    },
    {
      mutation: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
      payload: [],
    },
    {
      mutation: 'SET_TOTALS_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ON_ERROR',
      payload: {},
    },
    {
      mutation: 'SET_IS_SETTLED_CUSTOMER',
      payload: false,
    },
    {
      mutation: 'SET_IS_ORDER_INFORMATION_FORM_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
    {
      mutation: 'SET_IS_EVENT_INFORMATION_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
    {
      mutation: 'SET_IS_DISPATCH_AND_RETURN_ALL_REQUIRED_FIELDS_FILLED',
      payload: false,
    },
  ]

  // Methods === === === === =>
  const mapDate = date => {
    const getDate = new Date(date)

    return [getDate.getFullYear(), getDate.getMonth() + 1, getDate.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
  }

  const mapTime = time => {
    if (!time) return ''

    return time.split(':').slice(0, 2).join(':')
  }

  const getProps = (fieldName, fields) => ({
    is: fields[fieldName].type,
    field: fields[fieldName],
    name: fieldName,
  })

  function getDaysBetweenDates({ rentalStart, rentalEnd }) {
    if (!rentalStart || !rentalEnd) {
      return 0
    }
    return differenceInDays(
      new Date(rentalEnd),
      new Date(rentalStart),
    ) + 1
  }

  function calculatePriceOfProducts({ productsList, fieldName, storeActionKey }) {
    if (!productsList.length && !storeActionKey) return []
    /** @prop Record<keyOf productsList, string>[] productsList */
    /** @prop Record<string, keyOf productsList> fieldName */
    /** @prop String storeActionKey */
    const defaultFieldName = {
      price: 'price',
      discount: 'discount',
      days: 'days',
      amount: 'amount',
      startDate: 'startDate',
      endDate: 'endDate',
      discountPrice: 'discountPrice',
      taxes: 'taxes',
      totalPrice: 'totalPrice',
      ...fieldName,
    }

    const newProductsList = productsList.reduce((acc, product) => {
      const days = getDaysBetweenDates({
        rentalStart: product[defaultFieldName.startDate],
        rentalEnd: product[defaultFieldName.endDate],
      }) || 1

      const discount = product[defaultFieldName.discount] || 0
      const total = (product[defaultFieldName.price] ?? 0) * product[defaultFieldName.amount] * days
      const discountPrice = (total * discount) / 100
      const subtotal = total - discountPrice
      const totalPrice = parseFloat(product[defaultFieldName.taxes]) + subtotal

      return [
        ...acc,
        {
          ...product,
          [defaultFieldName.days]: days,
          [defaultFieldName.discount]: discount,
          [defaultFieldName.discountPrice]: discountPrice,
          [defaultFieldName.totalPrice]: totalPrice,
          subtotal,
        },
      ]
    }, [])
    return storeActionKey
      ? this.$store.commit(`${MODULE_NAME}/${storeActionKey}`, newProductsList)
      : newProductsList
    /**
     * @returns { Record<keyOf productsList, string>[] | void}
     */
  }

  return {
    store,
    model,
    catalog,
    mapTime,
    mapDate,
    getProps,
    MODULE_NAME,
    tableColumns,
    quoteStatusBar,
    attachmentModel,
    CREATE_PAGE_TITLE,
    MODULE_NAME_CLONE,
    MODULE_NAME_CATALOG,
    storeMutationsList,
    MODULE_NAME_ATTACHMENT,
    uploadAttachmentTableCol,
    FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PLACE_ORDER,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_AGAIN,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_HOLD_STOCKS,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
    customerSearchQuery,
    calculatePriceOfProducts,
    holdStatus,
    draftStatus,
    commonFormButtons,
    approveStatusButtons,
    rightSideHoldInProgressButtons,
    rightSideHoldReadyButtons,
    leftSideButtons,
  }
}

export default {
  catalog: {},
  catalogProductBarcode: null,
  catalogs: [],
  catalogForm: {},
  brandForm: {},
  export: {},
  exportPath: 'products/csv-export',
  exportPathTemplate: 'products/csv-template',
  import: {},
  exportButtonItems: [
    {
      btnTitle: 'Catalog Information',
      actionType: 'catalog_information',
    },
  ],
}
